import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { connect } from 'react-redux';
import Image from 'next/image';

const mapStateToProps = (state) => ({
  siteData: state.user.siteData,
});

const mapDispatchToProps = (dispatch) => ({});

function Footer(props) {
  const { siteData } = props;
  return (
    <>
      <section className="footer main-footer">
        {siteData && (
          <div className="container pt-4 pt-md-4">
            <div className="row text-md-start text-center">
              <div className="col-md-8 logo-section">
                <div>
                  {Object.keys(siteData).length > 0 && (
                    <img src={siteData?.footer?.logo} alt={siteData?.footer?.title} className="" height={80} />
                  )}
                </div>
                <div className="text-white fs-16 pe-md-5 mt-md-4 mt-2 font-sfpro">
                  {siteData.content?.detail_content.map((item, key) => (
                    <p key={key}>{item}</p>
                  ))}
                </div>
              </div>

              <div className="col-md-4 mt-md-5 font-proxima text-white">
                <h2 className="mb-2 fs-36">{siteData.content?.service_content.title}</h2>
                <ul className="fs-20 list-unstyled">
                  {siteData.content?.service_content.point.map((item, key) => (
                    <li key={key} className="mb-1">
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="row">
              <div className="col-12 footer-border py-md-4">
                {/* <div className='d-flex justify-content-center justify-content-md-end'>
                  <a href='https://www.facebook.com/apporio/' className='me-4' target={'blank'} title='Facebook'>
                    <FontAwesomeIcon icon={faFacebookF} className='text-white fs-25' />
                  </a>
                  <a href='https://twitter.com/ApporioInfolabs' target={'blank'} className='me-4' title='Twitter'>
                    <FontAwesomeIcon icon={faTwitter} className='text-white fs-25' />
                  </a>
                  <a href='https://www.instagram.com/apporioindia/' target={'blank'} className='' title='Instagram'>
                    <FontAwesomeIcon icon={faInstagram} className='text-white fs-25' />
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
